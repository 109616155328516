@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';



.App{
    display: flex;
    justify-content: center;
    background-image: url(bg.jpg);
}



.iconbutton{
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background-color: #7600bc;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 3%;
    bottom: 3%;
    cursor: pointer;
}


